<template>
  <v-card color="blue-grey darken-4" tile>
    <v-card-text>
      <v-row dense v-for="param, i in value.parameters" :key="i">
        <v-col cols="12">
          <v-card color="blue-grey darken-3">
            <v-card-text>
              <v-autocomplete
                placeholder="Параметр"
                class="borderless"
                v-model="value.parameters[i].id"
                item-value="id"
                item-text="title"
                :error-messages="errors['parameters.' + i +'.id']"
                :items="parameters"
              >
                <template v-slot:append-outer>
                  <v-btn color="error" icon @click="removeParameter(i)">
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn color="blue-grey lighten-2" small outlined @click="addParameter">
            <v-icon>mdi-plus</v-icon> Параметр
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: { type: Object },

    errors: { type: Object },

    parameters: {
      type: Array,
      default: () => ([{}])
    }
  },

  methods: {
    addParameter () {
      this.value.parameters.push({})
    },

    removeParameter (i) {
      this.value.parameters = this.value.parameters.filter((p, key) => key !== i)
    }
  },

  watch: {
    value: {
      deep: true,
      handler () {
        this.$emit('input')
      }
    }
  }
}
</script>

<style>

</style>
