<template>
  <card-set
    :index="index"
    :destroy="destroy"
  >
    <template v-slot:default="slotProps">
      <card
        :item="slotProps.item"
        :store="store"
        :update="update"
        :disabled="slotProps.item.id && !slotProps.item.acc_id"
        :fields="fields"
        @delete="slotProps.del(slotProps.item)"
      />
    </template>
  </card-set>
</template>

<script>
import Card from '@/components/Common/EditableCard'
import CardSet from '@/components/Common/CardSet'
import { mapActions } from 'vuex'

export default {
  components: {
    CardSet,
    Card
  },

  data: () => ({
    fields: [
      {
        type: 'title',
        key: 'title',
        title: 'Название'
      },
      {
        type: 'area',
        key: 'script',
        monospace: true,
        title: 'Код обработчика'
      }
    ]
  }),

  methods: {
    ...mapActions({
      index: 'normalizators/index',
      store: 'normalizators/store',
      update: 'normalizators/update',
      destroy: 'normalizators/destroy'
    })
  }
}
</script>

<style>
</style>
