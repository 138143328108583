<template>
  <v-card color="blue-grey darken-4" tile>
    <v-card-text>
      <v-row dense v-for="dictionary, i in value.dictionaries" :key="i">
        <v-col>
          <v-card color="blue-grey darken-3">
            <v-card-text>
              <v-text-field
                label="Название"
                v-model="value.dictionaries[i].title"
                autocomplete="off"
                :error-messages="errors['dictionaries.' + i +'.title']"
              >
                <template v-slot:append-outer>
                  <v-btn color="error" icon @click="removeParameter(i)">
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-row dense v-for="(item, j) in value.dictionaries[i].items" :key="j">
                <v-col cols="3">
                  <v-text-field
                    label="Ключ"
                    autocomplete="off"
                    v-model="value.dictionaries[i].items[j].key"
                    :error-messages="errors['dictionaries.' + i +'.items.' + j + '.key']"
                  />
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Значение"
                    v-model="value.dictionaries[i].items[j].value"
                    autocomplete="off"
                    :error-messages="errors['dictionaries.' + i +'.items.' + j + '.value']"
                  >
                    <template v-slot:append-outer>
                      <v-btn icon @click="removeDictionary(i)">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="text-end">
                  <v-btn
                    small
                    outlined
                    @click="value.dictionaries[i].items.push({})"
                    color="blue-grey lighten-2"
                  >
                    <v-icon>mdi-plus</v-icon> Запись
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn color="blue-grey lighten-2" small outlined @click="addDictionary">
            <v-icon>mdi-plus</v-icon> Словарь
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: { type: Object },

    errors: { type: Object }
  },

  methods: {
    addDictionary () {
      this.value.dictionaries.push({ items: [{}] })
    },

    removeDictionary (i) {
      this.value.dictionaries = this.value.dictionaries.filter((p, key) => key !== i)
    }
  },

  watch: {
    value: {
      deep: true,
      handler () {
        this.$emit('input')
      }
    }
  }
}
</script>

<style>

</style>
