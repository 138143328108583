<template>
  <v-card color="blue-grey darken-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="light-blue"
            :vertical="$vuetify.breakpoint.mdAndUp"
            show-arrows
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item><units /></v-tab-item>
            <v-tab-item><parameters /></v-tab-item>
            <v-tab-item><device-groups /></v-tab-item>
            <v-tab-item></v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Units from './Units/Index'
import Parameters from './Parameters/Index'
import DeviceGroups from './DeviceGroups/Index'

export default {
  components: {
    Parameters,
    DeviceGroups,
    Units
  },

  data () {
    return {
      tab: null,
      items: [
        'Единицы измерения', 'Параметры', 'Группы устройств'
        // ,'Тэги'
      ]
    }
  }
}
</script>

<style>

</style>
