<template>
  <card-set
    :index="index"
    :destroy="destroy"
    :emptyTemplate="{ vis_props: {
      icon: null
    } }"
  >
    <template v-slot:default="slotProps">
      <card
        :item="slotProps.item"
        :store="store"
        :update="update"
        :disabled="slotProps.item.id && !slotProps.item.acc_id"
        :fields="fields"
        @delete="slotProps.del(slotProps.item)"
      >
        <template v-slot:default="cardProps">
          <visual-props
            v-model="cardProps.item"
            :errors="cardProps.errors"
            @input="cardProps.extChange"
          />
        </template>
      </card>
    </template>
  </card-set>
</template>

<script>
import Card from '@/components/Common/EditableCard'
import CardSet from '@/components/Common/CardSet'
import VisualProps from './VisualProps'
import { mapActions } from 'vuex'

export default {
  components: {
    CardSet,
    VisualProps,
    Card
  },

  data () {
    return {
      fields: []
    }
  },

  mounted () {
    this.fields = [
      {
        type: 'title',
        key: 'title',
        title: 'Название'
      }
    ]
  },

  methods: {
    ...mapActions({
      index: 'deviceGroups/index',
      store: 'deviceGroups/store',
      update: 'deviceGroups/update',
      destroy: 'deviceGroups/destroy'
    })
  }
}
</script>

<style>
</style>
