<template>
  <v-card color="blue-grey darken-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="light-blue"
            :vertical="$vuetify.breakpoint.mdAndUp"
            show-arrows
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item><sensors /></v-tab-item>
            <v-tab-item><in-dev /></v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import InDev from '@/components/Common/InDev.vue'
import Sensors from '@/components/Settings/Models/Sensors/Index'

export default {
  components: {
    InDev,
    Sensors
  },

  data () {
    return {
      tab: null,
      items: [
        'Датчики', 'Актюаторы'
      ]
    }
  }
}
</script>

<style>

</style>
