<template>
  <v-alert
    border="top"
    colored-border
    type="info"
    prominent
    class="pt-9"
  >
    Раздел находится в разработке
  </v-alert>
</template>

<script>
export default {

}
</script>

<style>

</style>
