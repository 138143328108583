<template>
  <card-set
    :index="index"
    :destroy="destroy"
  >
    <template v-slot:default="slotProps">
      <card
        :item="slotProps.item"
        :store="store"
        :update="update"
        :disabled="slotProps.item.id && !slotProps.item.acc_id"
        :fields="fields"
        @delete="slotProps.del(slotProps.item)"
      />
    </template>
  </card-set>
</template>

<script>
import Card from '@/components/Common/EditableCard'
import CardSet from '@/components/Common/CardSet'
import { mapActions } from 'vuex'

export default {
  components: {
    CardSet,
    Card
  },

  data () {
    return {
      fields: []
    }
  },

  mounted () {
    this.getUnits().then(response => {
      const units = response.map(unit => ({
        value: unit.id,
        text: unit.title + ' (' + unit.designation + ')'
      }))
      this.fields = [
        {
          type: 'title',
          key: 'title',
          title: 'Название'
        },
        {
          type: 'autocomplete',
          key: 'def_unit_id',
          title: 'Единица измерения (по-умолчанию)',
          items: units
        }
      ]
    })
  },

  methods: {
    ...mapActions({
      index: 'parameters/index',
      store: 'parameters/store',
      update: 'parameters/update',
      destroy: 'parameters/destroy',
      getUnits: 'units/index'
    })
  }
}
</script>

<style>
</style>
