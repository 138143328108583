<template>
  <card-set
    :lg="12" :md="12" :xl="6"
    :index="index"
    :destroy="destroy"
    :emptyTemplate="{
      parameters: [{}],
      dictionaries: []
    }"
  >
    <template v-slot:default="slotProps">
      <card
        :item="slotProps.item"
        :store="store"
        :update="update"
        :disabled="slotProps.item.id && !slotProps.item.acc_id"
        :fields="fields"
        @delete="slotProps.del(slotProps.item)"
      >
        <template v-slot:default="cardProps">
          <v-tabs
            background-color="blue-grey darken-4"
            v-model="tab[slotProps.index]"
            color="blue-grey lighten-2"
            show-arrows
            grow
          >
            <v-tab>Параметры</v-tab>
            <v-tab>Словари</v-tab>
          </v-tabs>
          <v-card-text class="py-4 px-2">
            <v-tabs-items v-model="tab[slotProps.index]">
              <v-tab-item>
                <parameter-list
                  v-model="cardProps.item"
                  :errors="cardProps.errors"
                  @input="cardProps.extChange"
                  :parameters="parameters"
                />
              </v-tab-item>
              <v-tab-item>
                <dictionary-list
                  v-model="cardProps.item"
                  :errors="cardProps.errors"
                  @input="cardProps.extChange"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </template>
      </card>
    </template>
  </card-set>
</template>

<script>
import Card from '@/components/Common/EditableCard'
import CardSet from '@/components/Common/CardSet'
import ParameterList from './ParameterList'
import DictionaryList from './DictionaryList'

import { mapActions } from 'vuex'

export default {
  components: {
    CardSet,
    Card,
    ParameterList,
    DictionaryList
  },

  data: () => ({
    fields: [
      {
        type: 'title',
        key: 'title',
        title: 'Название'
      }
    ],
    parameters: [],
    tab: {}
  }),

  mounted () {
    this.getParameters().then((response) => {
      this.parameters = response
    })
  },

  methods: {
    ...mapActions({
      index: 'sensorModels/index',
      store: 'sensorModels/store',
      update: 'sensorModels/update',
      destroy: 'sensorModels/destroy',
      getParameters: 'parameters/index'
    })
  }
}
</script>

<style>
</style>
