<template>
  <v-card color="blue-grey darken-4" class="ma-6">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="blue-grey lighten-2"
      show-arrows
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item><dictionaries /></v-tab-item>
        <v-tab-item><models /></v-tab-item>
        <v-tab-item><normalizators /></v-tab-item>
        <v-tab-item><in-dev /></v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Dictionaries from '@/components/Settings/Dictionaries/Index'
import Models from '@/components/Settings/Models/Index'
import Normalizators from '@/components/Settings/Normalizators/Index'

import InDev from '@/components/Common/InDev.vue'
export default {
  components: {
    Dictionaries,
    Models,
    InDev,
    Normalizators
  },

  data () {
    return {
      tab: null,
      items: [
        'Справочники', 'Модели', 'Обработчики', 'Шаблоны'
      ]
    }
  }
}
</script>

<style>

</style>
